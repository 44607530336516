import utils from 'o365.modules.utils.js';
export function formatForCopyValue(value: any, colType: string) {
        let returnValue;
        if (value === null) {
            return "";
        }
        switch (colType) {
            case "time":
                returnValue = utils.formatDate(value, "HH:mm");
                break;
            case "number":
                returnValue = parseFloat(value);
                break;
            case "datetime":
                returnValue = utils.formatDate(value, "yyyy-MM-dd HH:mm:ss");
                break;
            case "boolean":
                if (value === "USANN" || value === "FALSE" || !value || value === 0 || value === "") {
                    returnValue = 0;
                } else {
                    returnValue = 1;
                }
                break;
            case "date":
                returnValue = utils.formatDate(value, "yyyy-MM-dd");
                break;
            case 'bit':
                returnValue = `${value}`;
                break;
            default:
                returnValue = value;
                // returnValue = value.replace(/\n/g, '').replace(/\r/g, '');
                break;
        }

        return returnValue !== null ? returnValue : "";
    }